<template>
    <div>
        <navigation msg="合作企业详情" ></navigation>
        <div class="PLR_4 PTB_3 bg_fff" >
            <div class="">
                <div class="H_44vw B_radius_10 bg_red_500">

                </div>
                <div class="fontS_18 font_weight_bold PTB_2 textL">
                    上海振翊软件科技有限公司
                </div>
                <div class="flex_row_between color_555">
                    <span>上海 | 入职企业</span>
                    <span>⚪</span>
                </div>
            </div>
        </div>
        <div class="MT_2 bg_fff PLR_4 PTB_3 bg_fff">
            <div class="PTB_3 flex_rowL">
                <span class="fontS_18 font_weight_bold PR_2">企业简介</span>
                <span>⚪</span>
            </div>
            <div class="lineH_3 textL color_555">
                中软国际是中国大型综合性软件与信息服务企业，提供从咨询、解决方案、技术服务到IT人才培养的“端到端”软件及信息服务，目前已经覆盖政府、制造流通…
            </div>
        </div>
        <div class="MT_2 bg_fff PLR_4 PTB_3 bg_fff">
            <div class="PTB_3 flex_rowL">
                <span class="fontS_18 font_weight_bold PR_2">企业地址</span>
                <span>⚪</span>
            </div>
            <div class="lineH_3 textL color_555">
                企业地址信息文字内容企业地址信息文字内容
            </div>
        </div>
        <div class="MT_2 bg_fff PLR_4 PTB_3 bg_fff">
            <div class="PTB_3 flex_row_between">
                <div class="flex_rowL">
                    <span class="fontS_18 font_weight_bold PR_2">企业信息</span>
                    <span>⚪</span>
                </div>
                <div class="color_F55814">
                    查看更多企业信息 >
                </div>
            </div>
            <div class="">
                <div class="flex_row_between PT_3">
                    <span class="color_333">公司名称</span>
                    <span class="color_555">上海振翊软件科技有限公司</span>
                </div>
                <div class="flex_row_between PT_3">
                    <span class="color_333">注册资本</span>
                    <span class="color_555">1000万</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import '@/assets/css/common.css';
    import navigation from '@/components/navigation/index';
    export default {
        name: "enterpriseDetail",
        data() {
            return {

            }
        },
        created() {
        },
        mounted() {
        },
        methods: {},
        components: {
            navigation
        }
    }
</script>

<style scoped>

</style>